<template>
  <v-select solo 
  prepend-inner-icon="mdi-view-list"
  :items="$helpers.status()"
  item-value="value"
  item-text="text"
  v-model="$store.state[store].status"
  v-on:input="searchStatus($event)"/>
</template>

<script>
export default {
  props: ['store','action'],
  methods: {
    searchStatus(event) {
      var vm = this
      vm.$store.dispatch(`${vm.store}/${vm.action}`, {
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: vm.$store.state[vm.store].date,
        count: vm.$store.state[vm.store].count,
        status: event
      })
    },
  }
}
</script>